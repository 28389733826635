'use client'

import { Button, Cell, Checkbox, InputText, Spacer, Text } from '@vinted/web-ui'
import { camelCase } from 'lodash'
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'

import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { getGoogleRedirectUrl } from '@marketplace-web/domain/socials-authentication'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { ErrorItem, ResponseError } from '@marketplace-web/shared/api-client'
import { navigateToPage, useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSystemConfiguration } from '@marketplace-web/shared/system-configuration'
import {
  ContentLoader,
  onA11yKeyDown,
  renderValidation,
  SeparatedList,
  useFormValidationMessage,
} from '@marketplace-web/shared/ui-helpers'
import { isValueInObject, normalizedQueryParam } from '@marketplace-web/shared/utils'

import { clickEvent } from '_libs/common/event-tracker/events'
import {
  BUSINESS_TERMS_AND_CONDITIONS_URL,
  BUSINESS_TERMS_OF_SALE_URL,
  BUSINESS_TERMS_URL,
  IMPRESSUM_URL,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { getUsernameSuggestion, validateUser } from 'data/api'
import { RegisterUserResponse } from 'types/api/response'

import { AuthView, NewsletterSubscription } from '../../constants'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import useAuthModal from '../../hooks/useAuthModal/useAuthModal'
import useAuthTracking from '../../hooks/useAuthTracking'
import useSuccessUrl from '../../hooks/useSuccessUrl/useSuccessUrl'
import { isNetworkError, isTimeoutError } from '../../utils/errors'
import PasswordField from '../PasswordField'
import { Field } from './constants'
import { FormData } from './types'
import { validateEmail, validateLogin, validatePassword, validateRealName } from './utils'

const TRANSLATION_PREFIX = 'auth.register'

const USERNAME_SUGGESTION_REAL_NAME_MIN_LENGTH = 3

const fieldToTrackingTargetMap = {
  [Field.RealName]: 'full_name',
  [Field.Login]: 'username',
  [Field.Email]: 'email',
  [Field.Password]: 'password',
  [Field.SubscribeToNewsletter]: ClickableElement.NewsletterCheckbox,
  [Field.AgreeRules]: ClickableElement.PoliciesCheckbox,
}

type Props = {
  realName?: string
  email?: string
  isPasswordRequired?: boolean
  onSubmit: (data: FormData) => Promise<ResponseError | RegisterUserResponse | null>
}

const RegisterForm = ({ realName, email, isPasswordRequired, onSubmit }: Props) => {
  const { searchParams } = useLocation()
  const { track } = useTracking()

  const { authView } = useAuthenticationContext()
  const { registrationRealNameRequired, newsletterSubscriptionType } =
    useSystemConfiguration() || {}
  const { isBusinessAuth } = useAuthModal()
  const isProTermsAndConditionsFSEnabled = useFeatureSwitch('pro_terms_and_conditions_enabled')

  const [baseError, setBaseError] = useState('')
  const [usernameSuggestion, setUsernameSuggestion] = useState('')
  const [showRealNameField, setShowRealNameField] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const state = normalizedQueryParam(searchParams.state)
  const stateUrl = getGoogleRedirectUrl(state)
  const successUrl = useSuccessUrl(stateUrl)
  const { trackInputEvent, trackClickEvent } = useAuthTracking()
  const isRealNameValidForSuggestion =
    (realName?.replace(/\s/g, '').length ?? 0) > USERNAME_SUGGESTION_REAL_NAME_MIN_LENGTH

  const {
    setError,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors, dirtyFields },
    setValue,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      realName,
    },
  })
  const getErrorMessage = useFormValidationMessage(errors, `${TRANSLATION_PREFIX}.fields`)
  const translate = useTranslate(TRANSLATION_PREFIX)
  const selectTypeTranslate = useTranslate('auth.select_type')
  const errorsTranslate = useTranslate('errors')
  const username = useWatch({ control, name: Field.Login })

  const usernameGeneratorSocialInputWebAbTest = useAbTest('username_generator_social_input_web')

  useTrackAbTest(usernameGeneratorSocialInputWebAbTest, isRealNameValidForSuggestion)

  const getUsernameSuggestionApiTrigger = useCallback(async () => {
    if (!realName || usernameSuggestion) return
    const response = await getUsernameSuggestion(realName)

    if ('errors' in response) return
    setUsernameSuggestion(response.username)
  }, [realName, usernameSuggestion])

  useEffect(() => {
    if (!isRealNameValidForSuggestion || usernameGeneratorSocialInputWebAbTest?.variant !== 'on') {
      return
    }

    getUsernameSuggestionApiTrigger()
  }, [
    isRealNameValidForSuggestion,
    usernameGeneratorSocialInputWebAbTest,
    getUsernameSuggestionApiTrigger,
  ])

  function trackLinkClick(target: ClickableElement | undefined) {
    if (!target) return undefined

    return () => trackClickEvent({ target })
  }

  function interpolateLink(url: string, eventTarget?: ClickableElement) {
    return (part: Array<ReactNode>) => (
      <a
        key={url}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackLinkClick(eventTarget)}
      >
        {part}
      </a>
    )
  }

  const setErrors = useCallback(
    (newErrors: Array<ErrorItem>) => {
      let newBaseError = ''
      let hasFieldError = false

      newErrors.forEach(({ field, value }) => {
        const fieldName = camelCase(field)

        if (fieldName === Field.RealName) setShowRealNameField(true)
        if (fieldName === 'base') newBaseError ||= value
        if (!isValueInObject(fieldName, Field)) return

        hasFieldError = true
        setError(fieldName, { type: 'manual', message: value })
      })

      setBaseError(hasFieldError ? '' : newBaseError)
    },
    [setError],
  )

  async function handleFormSubmit(data: FormData) {
    const response = await onSubmit(data)

    if (!response) return

    if (isNetworkError(response)) {
      setBaseError(selectTypeTranslate('errors.offline'))

      return
    }

    if (isTimeoutError(response)) {
      setBaseError(errorsTranslate('generic'))
      setIsLoading(false)

      return
    }

    if ('errors' in response) {
      setErrors(response.errors)

      return
    }

    if (
      usernameGeneratorSocialInputWebAbTest?.variant === 'on' &&
      authView !== AuthView.EmailRegister
    ) {
      const suggestedUsernameSubmissionTarget = {
        screen: Screen.CompleteRegistration,
        target: ClickableElement.SubmitSuggestedUsername,
        targetDetails: JSON.stringify({
          registered_username_same_as_suggested: usernameSuggestion
            ? usernameSuggestion === username
            : 'username_not_suggested',
        }),
      }

      track(clickEvent(suggestedUsernameSubmissionTarget))
    }

    navigateToPage(successUrl)
  }

  useDataDomeCaptcha(() => {
    const formData = getValues()

    if (!formData) return

    handleFormSubmit(formData)
  })

  const handleInputFocus = (target: string) => () => trackInputEvent({ target, state: 'focus' })

  const handleInputBlur = (target: string) => () => trackInputEvent({ target, state: 'unfocus' })

  const getInputEvents = (field: Field) => {
    const target = fieldToTrackingTargetMap[field]

    return {
      onFocus: handleInputFocus(target),
      onBlur: handleInputBlur(target),
    }
  }

  function handleCheckboxClick(
    field: Field.AgreeRules | Field.SubscribeToNewsletter,
    onChange: (isChecked: boolean) => void,
  ) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      trackClickEvent({
        target: fieldToTrackingTargetMap[field],
        targetDetails: event.target.checked ? 'checked' : 'unchecked',
      })

      onChange(event.target.checked)
    }
  }

  const handleSuggestionClick = () => {
    setValue(Field.Login, usernameSuggestion, { shouldDirty: true })

    const target = {
      screen: Screen.CompleteRegistration,
      target: ClickableElement.UseSuggestedUsername,
    }

    track(clickEvent(target))
  }

  function handleSubmitButtonClick(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (isSubmitting) {
      event.preventDefault()

      return
    }

    const target =
      authView === AuthView.EmailRegister
        ? ClickableElement.RegisterWithEmail
        : ClickableElement.RegisterWithSocial

    trackClickEvent({ target })
  }

  function renderBaseError() {
    if (!baseError) return null

    return (
      <div className="u-ui-padding-horizontal-large u-ui-padding-top-large u-ui-padding-bottom-small">
        <Text
          as="span"
          text={baseError}
          theme="warning"
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
        />
      </div>
    )
  }

  function renderRealNameField() {
    if (!registrationRealNameRequired && !showRealNameField) return null
    if (realName && !showRealNameField) {
      return <input {...register(Field.RealName)} type="hidden" value={realName} />
    }

    return (
      <InputText
        {...register(Field.RealName, {
          required: true,
          validate: value => (value ? validateRealName(value) : undefined),
        })}
        placeholder={translate('fields.real_name.title')}
        validation={
          renderValidation(getErrorMessage(Field.RealName)) || translate('fields.real_name.hint')
        }
        aria={{ 'aria-required': true }}
        {...getInputEvents(Field.RealName)}
      />
    )
  }

  function renderEmailField() {
    if (email) return <input {...register(Field.Email)} type="hidden" value={email} />

    const error = getErrorMessage(Field.Email)

    return (
      <InputText
        {...register(Field.Email, {
          required: true,
          validate: validateEmail,
        })}
        placeholder={translate('fields.email.title')}
        validation={
          error ? renderValidation(getErrorMessage(Field.Email)) : translate('fields.email.hint')
        }
        aria={{ 'aria-required': true }}
        {...getInputEvents(Field.Email)}
      />
    )
  }

  function renderUsernameSuggestion() {
    if (dirtyFields.login) return null
    if (!usernameSuggestion || !isRealNameValidForSuggestion) return null

    return (
      <>
        {translate('fields.login.username_suggestion').concat(': ')}

        <span role="button" tabIndex={0} onKeyDown={onA11yKeyDown} onClick={handleSuggestionClick}>
          <Text
            as="span"
            text={usernameSuggestion}
            theme="primary"
            underline
            clickable
            type={Text.Type.Caption}
          />
        </span>
      </>
    )
  }

  function translateTermsAndCondition(key: string) {
    return translate(key, {
      'terms-and-conditions': interpolateLink(TERMS_URL, ClickableElement.TermsAndConditions),
      'pro-terms-and-conditions': interpolateLink(BUSINESS_TERMS_AND_CONDITIONS_URL),
      'pro-terms-of-sale': interpolateLink(BUSINESS_TERMS_OF_SALE_URL),
      'pro-terms-of-use': interpolateLink(BUSINESS_TERMS_URL),
      'privacy-policy': interpolateLink(PRIVACY_POLICY_URL, ClickableElement.PrivacyPolicy),
      impressum: interpolateLink(IMPRESSUM_URL),
    })
  }

  function renderTermsAndConditionsCheckbox() {
    const error = getErrorMessage(Field.AgreeRules)
    const businessTermsAndConditionsKey = isProTermsAndConditionsFSEnabled
      ? 'fields.agree_rules.business.title_pro_terms_and_conditions'
      : 'fields.agree_rules.business.title'

    const checkboxLabel = isBusinessAuth
      ? translateTermsAndCondition(businessTermsAndConditionsKey)
      : translateTermsAndCondition('fields.agree_rules.title')

    return (
      <>
        <Controller
          defaultValue={false}
          render={({ field: { name, value, onChange } }) => (
            <Checkbox
              name={name}
              text={checkboxLabel}
              checked={value}
              onChange={handleCheckboxClick(name, onChange)}
              testId="terms-and-conditions-checkbox"
            />
          )}
          rules={{
            required: true,
          }}
          name={Field.AgreeRules}
          control={control}
        />
        {error && <Spacer size={Spacer.Size.Small} />}
        {renderValidation(error)}
      </>
    )
  }

  function renderFields() {
    return (
      <>
        {renderRealNameField()}
        <InputText
          {...register(Field.Login, {
            required: true,
            minLength: 3,
            maxLength: 20,
            validate: validateLogin,
          })}
          placeholder={translate('fields.login.title')}
          validation={
            renderValidation(getErrorMessage(Field.Login)) ||
            renderUsernameSuggestion() ||
            translate('fields.login.hint')
          }
          aria={{ 'aria-required': true }}
          {...getInputEvents(Field.Login)}
        />
        {renderEmailField()}
        {isPasswordRequired && (
          <PasswordField
            {...register(Field.Password, {
              required: true,
              validate: value => (value ? validatePassword(value) : undefined),
            })}
            validation={
              renderValidation(getErrorMessage(Field.Password)) || translate('fields.password.hint')
            }
            placeholder={translate('fields.password.title')}
            aria={{ 'aria-required': true }}
            {...getInputEvents(Field.Password)}
          />
        )}
        <Cell>
          <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>
            <Controller
              defaultValue={newsletterSubscriptionType === NewsletterSubscription.OptOut}
              render={({ field: { value, name, onChange } }) => (
                <Checkbox
                  name={name}
                  text={translate('fields.subscribe.title', {
                    // Only used for markets with a custom applied copy (e.g. Hungary)
                    'privacy-policy': interpolateLink(PRIVACY_POLICY_URL),
                  })}
                  checked={value}
                  onChange={handleCheckboxClick(name, onChange)}
                  testId="subscribe-newsletter-checkbox"
                />
              )}
              name={Field.SubscribeToNewsletter}
              control={control}
            />
            {renderTermsAndConditionsCheckbox()}
          </SeparatedList>
        </Cell>
      </>
    )
  }

  function renderFooter() {
    return (
      <Cell>
        <Button
          text={translate('actions.submit')}
          type={Button.Type.Submit}
          styling={Button.Styling.Filled}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={handleSubmitButtonClick}
        />
        <Spacer />
      </Cell>
    )
  }

  useEffect(() => {
    if (!registrationRealNameRequired) return

    async function handleValidateInitialUserData() {
      setIsLoading(true)

      const response = await validateUser({
        user: {
          real_name: realName,
        },
      })

      if ('errors' in response) {
        setErrors(response.errors)
      }

      setIsLoading(false)
    }

    handleValidateInitialUserData()
  }, [realName, setErrors, registrationRealNameRequired])

  if (isLoading) {
    return <ContentLoader />
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {renderBaseError()}
      {renderFields()}
      {renderFooter()}
    </form>
  )
}

export default RegisterForm
