'use client'

import { Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { registerFacebookUser } from 'data/api/authentication/requests'
import { getFingerprint } from '@marketplace-web/domain/audit'

import { ExternalRegisterData } from '../../types'
import RegisterForm, { FormData } from '../RegisterForm'
import { useRegistrationTracking } from '../../hooks/useRegistrationTracking/useRegistrationTracking'

type Props = ExternalRegisterData

const FacebookRegister = ({ idToken, realName, email }: Props) => {
  const translate = useTranslate()
  const trackRegistrationEvents = useRegistrationTracking()

  async function handleFormSubmit(data: FormData) {
    const fingerprint = await getFingerprint()

    const response = await registerFacebookUser({
      agreeRules: true,
      ...data,
      token: idToken,
      fingerprint,
    })

    await trackRegistrationEvents({
      response,
      authType: 'facebook',
      email: data.email,
    })

    return response
  }

  return (
    <>
      <div className="u-ui-padding-horizontal-large">
        <Text
          as="h1"
          id="auth_modal_title"
          text={translate('auth.external_register.title')}
          type={Text.Type.Heading}
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
        />
      </div>
      <RegisterForm email={email} realName={realName} onSubmit={handleFormSubmit} />
    </>
  )
}

export default FacebookRegister
