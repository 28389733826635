'use client'

import { useEffect, useState } from 'react'
import { Spacer, Text } from '@vinted/web-ui'

import { useGoogleTagManagerTrack } from '@marketplace-web/domain/google-track'
import { useLocation } from '@marketplace-web/shared/browser'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useUserAgent } from '@marketplace-web/shared/request'
import { ContentLoader, SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { isIOS, parseOS } from '@marketplace-web/shared/utils'

import { FormName, GoogleTagManagerEvent, PageType } from 'constants/google'
import { getReferrer } from 'data/api'
import { transformReferrerDto } from 'data/transformers/referrer'
import { ReferrerModel } from 'types/models/referrer'

import Facebook from '../Facebook'
import Google from '../Google'
import Apple from '../Apple'
import BusinessRegistrationLink from './BusinessRegistrationsLink'
import SelectTypeFooter from './SelectTypeFooter'
import SelectTypeHeader from './SelectTypeHeader'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import { AuthView } from '../../constants'
import RandomUserSignup from '../RandomUserSignup'

const SelectType = () => {
  const isRandomUserSignupEnabled = useFeatureSwitch('next_random_user_signup')
  const { authView } = useAuthenticationContext()
  const isRegisterView = authView === AuthView.SelectTypeRegister
  const { googleAnalyticsTrack } = useGoogleTagManagerTrack()

  const { s: registrationType } = useLocation().searchParams
  const userAgent = useUserAgent()
  const isAppleDevice = isIOS(userAgent) || parseOS(userAgent) === 'MacOS'

  const [error, setError] = useState<string>()
  const [hasReferralError, setHasReferralError] = useState(false)
  const [referrer, setReferrer] = useState<ReferrerModel>()

  useEffect(() => {
    function pushGoogleAnalyticsEvent() {
      if (isRegisterView) {
        googleAnalyticsTrack({
          event: GoogleTagManagerEvent.modalView,
          customTagObject: { formName: FormName.Register, pageType: PageType.Registration },
        })
      } else {
        googleAnalyticsTrack({
          event: GoogleTagManagerEvent.modalView,
          customTagObject: { formName: FormName.Login, pageType: PageType.Login },
        })
      }
    }

    pushGoogleAnalyticsEvent()
  }, [isRegisterView, googleAnalyticsTrack])

  const shouldFetchReferrer = isRegisterView && !referrer && registrationType === 'referral'

  useEffect(() => {
    if (!shouldFetchReferrer) return

    const fetchReferrer = async () => {
      const response = await getReferrer()

      if ('errors' in response || !response.referrer_id) {
        setHasReferralError(true)

        return
      }

      setReferrer(transformReferrerDto(response))
    }

    fetchReferrer()
  }, [shouldFetchReferrer])

  if (!hasReferralError && shouldFetchReferrer) {
    return (
      <ContentLoader
        styling={ContentLoader.Styling.Wide}
        size={ContentLoader.Size.Large}
        testId="select-type-loader"
      />
    )
  }

  const renderSocialLogins = () => {
    if (isAppleDevice) {
      return (
        <SeparatedList separator={<Spacer />}>
          <Apple setError={setError} />
          <Google setError={setError} />
          <Facebook setError={setError} />
          {isRandomUserSignupEnabled && isRegisterView && <RandomUserSignup />}
        </SeparatedList>
      )
    }

    return (
      <SeparatedList separator={<Spacer />}>
        <Google setError={setError} />
        <Apple setError={setError} />
        <Facebook setError={setError} />
        {isRandomUserSignupEnabled && isRegisterView && <RandomUserSignup />}
      </SeparatedList>
    )
  }

  return (
    <>
      <div className="u-ui-padding-horizontal-large u-ui-padding-bottom-x-large">
        <SelectTypeHeader referrer={referrer} />
        <Spacer />
        {error && (
          <Text
            as="span"
            text={error}
            theme="warning"
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            html
          />
        )}
        <Spacer size={Spacer.Size.Large} />
        {renderSocialLogins()}
        <Spacer size={Spacer.Size.XLarge} />
        <SelectTypeFooter />
      </div>
      {isRegisterView && <BusinessRegistrationLink />}
    </>
  )
}

export default SelectType
