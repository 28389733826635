import {
  API_BASE_PATH,
  ApiClient,
  csrfTokenInterceptor,
  errorInterceptor,
  localeInterceptor,
} from '@marketplace-web/shared/api-client'
import { ResetPasswordArgs } from 'types/api/args'

const api = new ApiClient({}, [csrfTokenInterceptor, errorInterceptor, localeInterceptor])

export function resetPassword({ email }: ResetPasswordArgs) {
  return api.post(`${API_BASE_PATH}/users/reset_password`, {
    email,
  })
}
