'use client'

import { useEffect } from 'react'

import { ErrorBoundary } from '@marketplace-web/shared/ui-helpers'

import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import { incrementPageLoadInitiatedCounter, handlePageLoadFailure } from '../../observability/utils'
import { ComponentLocation, ErrorReason } from '../../observability/constants'

type Props = {
  children: JSX.Element
  componentLocation: ComponentLocation
}

const AuthenticationErrorBoundary = ({ children, componentLocation }: Props) => {
  const { authView } = useAuthenticationContext()

  useEffect(() => {
    incrementPageLoadInitiatedCounter({ authView, componentLocation })
  }, [authView, componentLocation])

  const handleError = (error: Error | null) => {
    handlePageLoadFailure({
      error,
      authView,
      componentLocation,
      reason: ErrorReason.ErrorBoundary,
    })
  }

  return (
    <ErrorBoundary
      onError={handleError}
      FallbackComponent={ErrorBoundary.ComponentError}
      preventLog
    >
      {children}
    </ErrorBoundary>
  )
}

export default AuthenticationErrorBoundary
