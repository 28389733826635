'use client'

import { Response, ResponseError } from '@marketplace-web/shared/api-client'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { stringToSha256 } from '@marketplace-web/shared/utils'
import {
  authenticateSuccessEvent,
  userRegisterFailEvent,
  userRegisterSuccessEvent,
} from '_libs/common/event-tracker/events'
import { GoogleTagManagerEvent } from 'constants/google'
import { useGoogleTagManagerTrack } from '@marketplace-web/domain/google-track'
import { RegisterUserResponse } from 'types/api'

type RegistrationResponse = ResponseError<any> | Response<RegisterUserResponse>

type AuthType = Parameters<typeof userRegisterSuccessEvent>[0]['type']

type TrackRegistrationEventsArgs = {
  response: RegistrationResponse
  authType: AuthType
  email: string
}

const gtmTypeByAuthType: Record<AuthType, string> = {
  apple: 'apple',
  facebook: 'facebook',
  google: 'google',
  internal: 'email',
}

export const useRegistrationTracking = () => {
  const { track } = useTracking()
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()

  return async ({ response, authType, email }: TrackRegistrationEventsArgs) => {
    const gtmAuthType = gtmTypeByAuthType[authType]

    if ('errors' in response) {
      track(userRegisterFailEvent({ type: authType, errors: response.errors }))

      return
    }

    const formattedEventId = await stringToSha256(response.user.id.toString())
    const event = {
      event_id: formattedEventId,
      auth_type: gtmAuthType,
      user_email: email,
    }
    googleTagManagerTrack(GoogleTagManagerEvent.Register, event)
    track(userRegisterSuccessEvent({ type: authType, userId: response.user.id }))
    track(authenticateSuccessEvent({ type: authType, userId: response.user.id }))
  }
}
